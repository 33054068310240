<template>
  <v-navigation-drawer
    color="primary"
    :value="open"
    app
    :mini-variant="mini"
    mini-variant-width="75"
    fixed
    persistent
    mobile-breakpoint="991"
    width="260"
  >
    <!-- <template v-slot:append>

    </template>-->
    <v-list class="py-0">
      <span v-for="(item, index) in allApplications" :key="`${item.name}_${index}`">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" :to="item.path" class="left-nav-item">
              <v-list-item-avatar class="text-center">
                <v-icon color="secondary secondary--text" class="md-icon">{{ item.meta.nav.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-title class="secondary--text">{{ item.meta.nav.name }}</v-list-item-title>
            </v-list-item>
          </template>
          <span>{{item.meta.nav.name}}</span>
        </v-tooltip>
      </span>
    </v-list>
    <template slot="append">
      <v-tooltip right v-if="_isUnoappUser() || _isSuperUser()">
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on" :to="{name: 'Businesses'}" class="left-nav-item">
            <v-list-item-avatar class="text-center">
              <v-icon color="secondary secondary--text" class="md-icon"> fas fa-user-cog </v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="secondary--text">Admin</v-list-item-title>
          </v-list-item>
        </template>
        <span>Admin</span>
      </v-tooltip>
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on" :to="{name: 'Business Settings'}" class="left-nav-item">
            <v-list-item-avatar class="text-center">
              <v-icon color="secondary secondary--text" class="md-icon">fas fa-cog</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="secondary--text">Business Settings</v-list-item-title>
          </v-list-item>
        </template>
        <span>Business Settings</span>
      </v-tooltip>
    </template>
    <!-- PROFILE MODAL -->
  </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      mini: true
    }
  },
  props: {
    open: {
      default: true,
      required: true
    }
  },
  components: {
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      vg_activeBusinessBrandingAndLogo: 'auth/activeBusinessBrandingAndLogo',
      vg_user: 'auth/user',
      vg_isLocationsEnabled: 'applications/isLocationsEnabled'
    }),
    allApplications() {
      let allRoutes = this.$router.options.routes
      return allRoutes.filter((route) => {
        if (route.name === 'LocationsAndGroups') {
          return !!this.vg_isLocationsEnabled
        }
        if (route.name === 'Logs') {
          return this._isAdminUser() || this._isCorporateUser()
        }
        return route.meta && route.meta.nav && route.meta.nav.display
      })
    }
    // isUnoappUser() {
    //   return this.vg_user.type === 'unoapp'
    // },
    // isSuperUser() {
    //   return this.vg_user.type === 'super'
    // }
  }
}
</script>
<style scoped>
.branding-logo {
  max-width: 80%;
  height: 60px;
  width: auto;
}
</style>
